import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Members from "../data/members.yml";
export const pageQuery = graphql`
  query teamMembers {
  allMembersYaml {
    edges {
      node {
        name
        image {
          childImageSharp {
            fluid (maxWidth: 150, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <section id="team" className="align-center">
      <h1> Our team</h1>
      <div className="members-container">
        {props.data.allMembersYaml.edges.map(({
          node
        }) => <div className='member-container'>
      <div className="member-image">
        <Img fluid={node.image.childImageSharp.fluid} mdxType="Img" />
      </div>
      <div className="member-name">
        {node.name}
      </div>
    </div>)}
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      